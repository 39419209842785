import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: "https://7944495ba280454c80e73647c55b8cbe@o617827.ingest.sentry.io/5751258",
  release: "bd0d283aaf3386bf0ba82ccc01f449aaf97c0c20",
  environment: "production",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.25,
});
